
import {
  defineComponent
} from "vue";


export default defineComponent({
  name: "CardStatusImplantacao",
  props: {
    title: String,
    sla: Number,
    dotColor: String,
    iconName: String,
    arrow: Boolean
  },

  setup(props) {

    return {
      history,
    };
  },
});
