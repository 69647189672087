import Api from "@/services/Api"
import { defineStore } from "pinia";

export const useAtendimentoStore = defineStore("Atendimento", {
  state: (): any => {
    return {
      atendimentos: [] as any[],
      date: "",
      atendimentosPorRegiao: {} as any,
      produtosObj: {} as any,
      regioes: new Set(),
      cardName: "",
      faturamentoAtendimentos: null,
    }
  },
  actions: {
    async getAtendimentos(idGrupo: number,finalizados: boolean, idLojas: number[], idGrupos: number[],  regiao: number[]){
      this.atendimentosPorRegiao = {}
      try{
        
        const { data } = await Api.get('getDashboardAtendimentosDetalhados',{ idGrupo, date: this.date, finalizados, idLojas, idGrupos, regiao})
        this.atendimentos = data
        this.faturamentoAtendimentos = data.reduce((acc, item) => {
          acc += parseFloat(item.valor.replace(',', '.')) || 0
          return acc
        }, 0)

        const result = {}
        data.forEach(function (item: any) {
          //construindo objeto para montar treeview de atendimentos por região
          if (!result[item.ESTADO]) {
              result[item.ESTADO] = {atendimentos: 1, gn: {}};
          }

          if(!result[item.ESTADO].gn[item.GN]){
            result[item.ESTADO].gn[item.GN] = {atendimentos: 1, lojas: {}}
          }

          if(!result[item.ESTADO].gn[item.GN].lojas[item.loja]){
            result[item.ESTADO].gn[item.GN].lojas[item.loja] = { atendimentos: 1}
          }else{
            result[item.ESTADO].gn[item.GN].lojas[item.loja].atendimentos +=1
            result[item.ESTADO].gn[item.GN].atendimentos +=1
            result[item.ESTADO].atendimentos +=1
          }

        })
        this.atendimentosPorRegiao = result
      }catch(error){
        console.log(error)
      }
    },
    async getDashboardItensAtendimentosDetalhados(idGrupo: number,finalizados: boolean, idLojas: number[], idGrupos: number[],  regiao: number[]){
      this.produtosObj = {}
      try{
        const { data } = await Api.get('getDashboardItensAtendimentosDetalhados',{ idGrupo, date: this.date, finalizados, idLojas, idGrupos, regiao})
        // this.atendimentos = data
        this.faturamentoAtendimentos = data.reduce((acc, item) => {
          acc += parseFloat(item.valor.replace(',', '.')) || 0
          return acc
        }, 0)

        const produtos = {}
        data.forEach(function (item: any) {

          const itemValor = parseFloat(item.valor)
          //construindo objeto para montar treeview de produtos
          if (!produtos[item.tipoItem]) {
            produtos[item.tipoItem] = { qtd: item.qtd, valor: itemValor, ticketMedio: null, marcas: {} };
          } else {
              produtos[item.tipoItem].qtd += item.qtd; 
              produtos[item.tipoItem].valor += itemValor;
          }
          
          if (!produtos[item.tipoItem].marcas[item.codMarca]) {
              produtos[item.tipoItem].marcas[item.codMarca] = { qtd: item.qtd, valor: itemValor, ticketMedio: null, produtos: {} };
          } else {
              produtos[item.tipoItem].marcas[item.codMarca].qtd += item.qtd;  
              produtos[item.tipoItem].marcas[item.codMarca].valor += itemValor;
          }
          
          if (!produtos[item.tipoItem].marcas[item.codMarca].produtos[item.descricaoLongaProduto]) {
              produtos[item.tipoItem].marcas[item.codMarca].produtos[item.descricaoLongaProduto] = { qtd: item.qtd, valor: itemValor, ticketMedio: null };
          } else {
              produtos[item.tipoItem].marcas[item.codMarca].produtos[item.descricaoLongaProduto].qtd += item.qtd;  
              produtos[item.tipoItem].marcas[item.codMarca].produtos[item.descricaoLongaProduto].valor += itemValor;
          }
          //calcular ticket medio
          produtos[item.tipoItem].ticketMedio = produtos[item.tipoItem].valor / produtos[item.tipoItem].qtd;
          
          produtos[item.tipoItem].marcas[item.codMarca].ticketMedio = produtos[item.tipoItem].marcas[item.codMarca].valor / produtos[item.tipoItem].marcas[item.codMarca].qtd;

          produtos[item.tipoItem].marcas[item.codMarca].produtos[item.descricaoLongaProduto].ticketMedio = produtos[item.tipoItem].marcas[item.codMarca].produtos[item.descricaoLongaProduto].valor / produtos[item.tipoItem].marcas[item.codMarca].produtos[item.descricaoLongaProduto].qtd;
        })
        this.produtosObj = produtos 
      }catch(error){
        console.log(error)
      }
    },
    sendDate(date: string){
      this.date = date
    },
    sendCardName(cardName: string){
      this.cardName = cardName
    }
  },
  getters: {
   getArrayAtendimentos(): any[]{
    return this.atendimentos
   },
   getAtendimentosPorRegiao(): any[]{
    return this.atendimentosPorRegiao
   },
   getCardName(): string{
    return this.cardName
   },
   getProdutosObj(): any{
    return this.produtosObj
   },
   getFaturamentoAtendimentos(): number{
    return this.faturamentoAtendimentos
   },
  }
});





