
  import { ref, Ref, } from 'vue';
import { boolean } from 'yup';
  
  export default {
    name: 'Treeview',
    props: {
      folderName: [String, Number],
      valor: [Number, String],
      disableArrow: Boolean,
      disableFolder: Boolean,
      folderColor: String,
      arrowColor: String
    },
    setup(props) {
  
      const timer = ref();
      const isOut = ref(false);
      const isOpen: Ref<boolean> = ref(false);
      
      function closeModalClickOut() {
        isOut.value = !isOut.value
          timer.value = setTimeout(() => {
            isOut.value = !isOut.value
          }, 205)
        
        const clearTimeoutFn = () => {
          clearTimeout(timer.value);
        };
  
        clearTimeoutFn()
      }
  
      const toggleOpen = () => {
        closeModalClickOut()
          setTimeout(() => {
            isOpen.value = !isOpen.value
            
          }, 205)
      };
  
      return {
        isOpen,
        toggleOpen,
        isOut
      };
    },
  };
  