
  import { defineComponent, watch, ref } from "vue";
  import type { Ref } from "vue";
  import Treeview from "@/components/Treeview.vue";
  import { useAtendimentoStore } from "@/store/AtendimentoStore";  
  import Title from "@/components/Title.vue";
  export default defineComponent({
    name: "ModalTickets",
    components: {
      Treeview,
      Title
  },
    props: {
      fechaModal: Function,
      abreModal: Function,
    },

    setup(props) {
      const atendimentoStore = useAtendimentoStore();
      const ultimosAtendimentos: Ref<any[]> = ref([]);
      const arrayTodosAtendimentos: Ref<any[]> = ref([]);
      const atendimentosPorRegiao: Ref<any> = ref({});
      const produtosVendidosQtd: Ref<any> = ref({});
      const produtosTicketMedio: Ref<any> = ref({});
      const produtosFaturamento: Ref<any> = ref({});
      const cardName: Ref<string> = ref("");
      const faturamentoAtendimentos: Ref<number|null> = ref(null);


      function formataValor(value){
        const formattedValue = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL'
        }).format(value);
        return formattedValue
      }

      function toInteger(number: number){
        return Math.floor(number);
      } 

     function sortData(obj: any, tipo:string) {
      //função ordena objeto da treeview para que o maior apareça em cima
        const ordered: any = {};

        Object.keys(obj).sort((a, b) => {
          if (typeof obj[a] === 'object' && typeof obj[b] === 'object') {
              return obj[b][tipo] - obj[a][tipo];
          }
          return 0;
        }).forEach(key => {
          ordered[key] = obj[key];
          if (typeof obj[key] === 'object') {
            ordered[key] = sortData(obj[key], tipo);
          }
        });

        return ordered;
      }


      watch(
        () => atendimentoStore.atendimentos,
        () => {
          ultimosAtendimentos.value = atendimentoStore.getArrayAtendimentos.slice(0,10)
          arrayTodosAtendimentos.value = atendimentoStore.getArrayAtendimentos
        }
      )

      watch(
        () => atendimentoStore.atendimentosPorRegiao,
        () => {
          atendimentosPorRegiao.value = sortData(atendimentoStore.getAtendimentosPorRegiao, 'atendimentos')
        }
        )
        
        watch(
          () => atendimentoStore.cardName,
          () => {
            cardName.value = atendimentoStore.getCardName
          }
          )
          watch(
            () => atendimentoStore.produtosObj,
            () => {
          produtosVendidosQtd.value = sortData(atendimentoStore.getProdutosObj, 'qtd')
          produtosFaturamento.value = sortData(atendimentoStore.getProdutosObj, 'valor')
          produtosTicketMedio.value = sortData(atendimentoStore.getProdutosObj, 'ticketMedio')
          faturamentoAtendimentos.value = atendimentoStore.getFaturamentoAtendimentos
        }
      )

  
      return {
        ultimosAtendimentos,
        atendimentosPorRegiao,
        cardName,
        arrayTodosAtendimentos,
        formataValor,
        faturamentoAtendimentos,
        produtosTicketMedio,
        produtosVendidosQtd,
        produtosFaturamento,
        toInteger,
        sortData,
      };
    },
  });
  