
import { toRefs, defineComponent, PropType} from 'vue'

interface DadosAtendimentos {
  title: string;
  quantity: number;
  percentage: string;
  value?: number;
  iconFilename: string;
  displayValorFaturado: boolean,
  mediaDiasParaRetorno?: number,
  line?: boolean
}

export default defineComponent({
  name: "CardAtendimentos",
  props: {
    data: {
      type: Object as PropType<DadosAtendimentos>,
      required: true
    }
  },
  setup(props) {
    const { title, quantity, percentage, value, iconFilename, displayValorFaturado, mediaDiasParaRetorno, line } = toRefs(props.data);

    return { 
      title,
      quantity,
      percentage,
      value,
      iconFilename,
      displayValorFaturado,
      mediaDiasParaRetorno,
      line
    };
  }
})
