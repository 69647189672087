
import {
  defineComponent, onMounted, ref, Ref
} from "vue";
import Title from "@/components/Title.vue"
import CardStatusImplantacao from "@/views/dashboard/components/CardStatusImplantacao.vue"
import Api from "@/services/Api";

export default defineComponent({
  name: "ImplantacoesInfo",

  components: {
    Title,
    CardStatusImplantacao
  },

  setup() {

    const loadingSla: Ref<boolean> = ref(false)
    const slaImplantacao: Ref<any> = ref()
    const slaGeral: Ref<number> = ref(0)
    const series: Ref<number[]> = ref([])
    const lojas: Ref<any> = ref([])
    const chartOptions: Ref<any> = ref({
      chart: {
        type: 'donut'
      },
      labels: ['Cadastro do cliente no APP Indicar', 'Aguardando base de produtos da automação', 'Aguardando chegada do tablet', 'Preparando setup'],
      colors: ['#2BACE8', '#FFB822', '#F64E60', '#203972'],
      dataLabels: {
        enabled: true,
        formatter: function (val, opt) {
          return opt.w.config.series[opt.seriesIndex]
        },
      },
      legend: {
        show: false
      },
      responsive: [{
        breakpoint: 1250,
        options: {
          chart: {
            width: '100%'
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    })

    async function getDashboardDadosImplatacao() {
      try {
        const { data } = await Api.post('getDashboardDadosImplantacao', { pdvs: lojas.value })
        slaImplantacao.value = data
        sendDataToChartOptions(data)
      } catch (error) {
        console.log(error)
      } finally {
        loadingSla.value = true
      }
    }

    function sendDataToChartOptions(data) {
      for (const property in data) {
          if (property !== 'totalLojas') {
          series.value.push(data[property])
          slaGeral.value = slaGeral.value + data[property]
        }
      }
    }

    async function getLojas() {
      const { data } = await Api.get('tickets/getLojas')
      lojas.value = data
    }

    onMounted(async () => {
      await getLojas()
      getDashboardDadosImplatacao()
    })


    return {
      history,
      getDashboardDadosImplatacao,
      slaImplantacao,
      series,
      chartOptions,
      slaGeral,
      loadingSla
    };
  },
});
